import React, { Component } from "react";
import $ from "jquery";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Testimonials from "./components/Testimonials";

class App extends Component {
  constructor() {
    super();
    this.state = {
      resumeData: {},
      sharedData: {},
      testimonialsFeedback: {},
    };
  }

  componentDidMount() {
    this.loadSharedData();
    this.loadTestimonials();
    var resumePath = "info.json";
    this.loadResumeFromPath(resumePath);
  }

  loadResumeFromPath(path) {
    $.ajax({
      url: path,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function(xhr, status, err) {
        alert(err);
      },
    });
  }

  loadSharedData() {
    $.ajax({
      url: `portfolio_shared_data.json`,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({
          sharedData: data,
        });
        document.title = `${this.state.sharedData.basic_info.name}`;
      }.bind(this),
      error: function(xhr, status, err) {
        alert(err);
      },
    });
  }

  loadTestimonials() {
    $.ajax({
      url: `testimonials.json`,
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ testimonialsFeedback: data });
      }.bind(this),
      error: function(xhr, status, err) {
        alert(err);
      },
    });
  }

  render() {
    return (
      <div>
        <Header sharedData={this.state.sharedData.basic_info} />
        <About
          resumeBasicInfo={this.state.resumeData.basic_info}
          sharedBasicInfo={this.state.sharedData.basic_info}
        />
        <Testimonials
          testimonialsFeedback={this.state.testimonialsFeedback.testimonials}
        />

        <Skills
          sharedSkills={this.state.sharedData.skills}
          sharedTools={this.state.sharedData.tools}
          resumeBasicInfo={this.state.resumeData.basic_info}
        />
        <Experience
          resumeExperience={this.state.resumeData.experience}
          resumeBasicInfo={this.state.resumeData.basic_info}
        />
        <Projects
          resumeProjects={this.state.resumeData.projects}
          resumeBasicInfo={this.state.resumeData.basic_info}
        />
        <Footer sharedBasicInfo={this.state.sharedData.basic_info} />
      </div>
    );
  }
}

export default App;
